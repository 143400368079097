import { Button as AntdButton } from "antd";
import { ButtonProps } from "antd/es/button";

import Styles from "./button.module.scss";

interface IProps extends ButtonProps {
	iconRight?: boolean;
	fullWidth?: boolean;
}

const CustomButton = (props: IProps) => {
	const { children, className, iconRight, fullWidth, danger, ...rest } = props;
	//For compact button send size="small"
	// For primary link send type="link"
	// For secondary link send type="text"
	const type = rest.type;
	const icon = rest.icon;

	return (
		<AntdButton
			className={`${className ?? ""} ${Styles.button} ${
				type === "link" ? Styles.link : ""
			} ${type === "primary" ? Styles.primary : ""} ${
				type === "default" ? Styles.default : ""
			} ${type === "text" ? Styles.text : ""} ${
				!children && !!icon ? Styles["icon-only"] : ""
			} ${iconRight ? Styles["icon-right"] : ""} ${
				fullWidth ? Styles["full-width"] : ""
			} ${danger ? Styles["danger"] : ""}`}
			{...rest}
			danger={false}
			type={undefined}
			onClick={rest?.disabled || rest?.loading ? undefined : rest?.onClick}
		>
			{children}
		</AntdButton>
	);
};

export default CustomButton;
