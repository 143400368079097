import { useEffect, useState } from "react";
import { Col, Form, Row, message, Tooltip } from "antd";
import { AxiosResponse } from "axios";
import { isValidPhoneNumber } from "react-phone-number-input";

import {
  Input,
  TextArea,
  Select,
  MobileInput,
} from "../../../../../components/form-fields";
import { api } from "../../../../../api";
import { getApiErrorMsg } from "../../../../../utils/object-util";
import UploadIcon from "../../../../../assets/images/upload.svg";
import Spinner from "../../../../../components/spinner";
import UploadedFile from "../../../../../components/uploaded-file";
import CustomButton from "../../../../../components/button";
import { useCountryList } from "../../../../../hooks";
import { validateTextLimit } from "../../../../../utils/function-utils";
import Upload from "../../../../../components/upload";

interface Props {
  fromPublicPage?: boolean;
}

const CandidateBasicForm = ({ fromPublicPage = false }: Props) => {
  const form = Form.useFormInstance();
  const [uploadingPDF, setUploadingPDF] = useState<boolean>(false);
  const [countries, setCountries] = useState<string[]>();
  const [states, setStates] = useState<Array<string>>([]);
  const [cityData, setCityData] = useState<Array<string>>([]);
  const { getCity, getCountries, getStates } = useCountryList();

  const uploadSuccessCallback = (response : AxiosResponse<any, any>) => {
    if (response?.data?.data?.file) {
      form.setFieldValue("resume", response.data.data.file);
      form.validateFields(["resume"]);
    }
  }
  
  const removeFile = () => {
    form.setFieldValue("resume", undefined);
    form.validateFields(["resume"]);
  };

  useEffect(() => {
    if (getCountries) {
      const getCountriesList = getCountries();
      setCountries(getCountriesList);
    }
  }, []);

  useEffect(() => {
    if (fromPublicPage) {
      const country = form.getFieldValue(["address", "personal", "country"]);
      const state = form.getFieldValue(["address", "personal", "state"]);
      handleCountryChange(country, true);
      handleStateChange(state, true);
    }
  }, [fromPublicPage]);

  const handleCountryChange = (val: string, trigger?: boolean) => {
    if (getStates) {
      const getStatesList = getStates(val);
      setStates(getStatesList);
    }

    if (!trigger) {
      form.setFieldValue(["address", "personal", "state"], undefined);
      form.setFieldValue(["address", "personal", "city"], undefined);
    }
    setCityData([]);
  };

  const handleStateChange = (val: string, trigger?: boolean) => {
    const getCounty = form.getFieldValue(["address", "personal", "country"]);
    if (getCity) {
      const getCityList = getCity(getCounty, val);
      setCityData(getCityList);
    }

    if (!trigger) {
      form.setFieldValue(["address", "personal", "city"], undefined);
    }
  };

  return (
    <Row gutter={20}>
      <Col sm={24} md={12}>
        <Form.Item
          label="First name"
          name="first_name"
          rules={[
            {
              required: true,
              message: "Please input first name!",
              whitespace: true,
            },
          ]}
        >
          <Input placeholder="Enter First Name" />
        </Form.Item>
      </Col>

      <Col sm={24} md={12}>
        <Form.Item
          label="Last name"
          name="last_name"
          rules={[
            {
              required: true,
              message: "Please input last name!",
              whitespace: true,
            },
          ]}
        >
          <Input placeholder="Enter Last Name" />
        </Form.Item>
      </Col>

      <Col sm={24} md={12}>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input email!",
              whitespace: true,
            },
            { type: "email", message: "Please input valid email!" },
          ]}
        >
          <Input disabled={fromPublicPage} placeholder="Enter Email Address" />
        </Form.Item>
      </Col>

      <Col sm={24} md={12}>
        <Form.Item
          label="Mobile"
          name="phone_number"
          rules={[
            {
              required: true,
              message: "Please input mobile number!",
            },
            () => ({
              validator(_, value) {
                if (
                  value &&
                  !isValidPhoneNumber(
                    `${value}`?.startsWith("+") ? value : `+${value}`
                  )
                ) {
                  return Promise.reject("Please input valid mobile number!");
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
        >
          <MobileInput />
        </Form.Item>
      </Col>

      <Col sm={24} md={12}>
        <Form.Item
          label="Address"
          name={["address", "personal", "address_line"]}
          rules={[
            {
              required: true,
              message: "Please input address!",
              whitespace: true,
            },
            {
              validator: (_, value) => validateTextLimit(value, 500),
            },
          ]}
        >
          <TextArea autoSize={{ minRows: 6, maxRows: 6 }} />
        </Form.Item>
      </Col>

      <Col sm={24} md={12}>
        <Form.Item
          label="Country"
          name={["address", "personal", "country"]}
          rules={[
            {
              required: true,
              message: "Please select country!",
              whitespace: true,
            },
          ]}
        >
          <Select
            options={countries?.map((el) => ({
              value: el,
              label: el,
            }))}
            onChange={(val) => handleCountryChange(val)} // To modify the trigger settings, send only the desired value to the change method, as Antd automatically includes the option parameter in the second argument by default.
            placeholder="Select country"
          />
        </Form.Item>
      </Col>

      <Col sm={24} md={12}>
        <Form.Item
          label="State"
          // name="state"
          name={["address", "personal", "state"]}
          rules={[
            {
              required: states.length !== 0,
              message: "Please select state!",
              whitespace: true,
            },
          ]}
        >
          <Select
            options={states?.map((el) => ({
              value: el,
              label: el,
            }))}
            onChange={(val) => handleStateChange(val)} // To modify the trigger settings, send only the desired value to the change method, as Antd automatically includes the option parameter in the second argument by default.
            placeholder="Select state"
          />
        </Form.Item>
      </Col>

      <Col sm={24} md={12}>
        <Form.Item
          label="City"
          name={["address", "personal", "city"]}
          rules={[
            {
              required: cityData.length !== 0,
              message: "Please select city!",
              whitespace: true,
            },
          ]}
        >
          <Select
            options={cityData?.map((el) => ({
              value: el,
              label: el,
            }))}
            placeholder="Select city"
          />
        </Form.Item>
      </Col>

      <Col sm={24} md={12}>
        <Form.Item
          shouldUpdate={(prev: any, curr: any) => prev?.resume !== curr?.resume}
        >
          {({ getFieldValue }) => {
            const attachment = getFieldValue("resume");
            return (
              <Form.Item
                label="Upload resume"
                name="resume"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: !uploadingPDF ? "Please upload resume!" : "",
                  },
                ]}
              >
                {typeof attachment === "string" ? (
                  <UploadedFile url={attachment} onRemove={removeFile} icon />
                ) : uploadingPDF ? (
                  <div style={{ maxWidth: "50px" }}>
                    <Spinner size="small" wrapperStyle={{ paddingTop: 0 }} />
                  </div>
                ) : (
                  <Tooltip title="Maximum size 2 MB" placement="left">
                    <Upload
											accept=".pdf,.doc,.docx"
											uploadingFile={uploadingPDF}
											setUploadingFile={setUploadingPDF}
                      successCallback={uploadSuccessCallback}
										>
                      <CustomButton type="default">
                        <img
                          src={UploadIcon}
                          alt=""
                          style={{ paddingRight: "13px" }}
                        />
                        Choose file
                      </CustomButton>
                    </Upload>
                  </Tooltip>
                )}
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
    </Row>
  );
};

export default CandidateBasicForm;
