import { PopconfirmProps, Tooltip } from "antd";
import { useRef } from "react";

import DeleteFileIcon from "../../assets/images/cross-icon-black.svg";
import { fileIcon } from "../../constants/file-icon";
import Popconfirm from "../pop-confirm";
import Spinner from "../../components/spinner";

import styles from "./uploadedFile.module.scss";

interface IBaseProps {
	url: string;
	canDownload?: boolean;
	size?: "small" | "large";
	wrapperStyle?: React.CSSProperties;
	icon?: boolean;
	noBorder?: boolean;
	name?: string;
	loading?: boolean;
}

interface PropsWithPopConfirm extends IBaseProps {
	onRemove: () => void;
	popover?: true;
	popConfirmProps?: PopconfirmProps;
}

interface PropsWithoutPopConfirm extends IBaseProps {
	onRemove?: () => void;
	popover?: never | false;
	popConfirmProps?: never | undefined;
}

type IProps = PropsWithPopConfirm | PropsWithoutPopConfirm;

const UploadedFile = (props: IProps) => {
	const {
		url,
		canDownload,
		onRemove,
		size,
		wrapperStyle,
		icon = true,
		noBorder = false,
		name,
		popover = false,
		popConfirmProps,
		loading = false,
	} = props;
	const ref = useRef<HTMLDivElement>(null);

	const getFileNameFromUrl = (): string => {
		const tempName = url.substring(url?.lastIndexOf("/") + 1);
		return decodeURIComponent(tempName);
	};

	const getFileExtension = () => {
		const ext = name
			? name.substring(name.lastIndexOf(".") + 1)
			: url.substring(url.lastIndexOf(".") + 1);
		return fileIcon(ext);
	};

	const fileName = name ?? getFileNameFromUrl();
	const getIcon = getFileExtension();

	const onDownload = () => {
		if (canDownload) {
			const aTag = document.createElement("a");
			aTag.href = url;
			aTag.download = fileName;
			aTag.target = "_blank";
			document.body.appendChild(aTag);
			aTag.click();
			document.body.removeChild(aTag);
		}
	};

	return (
		<div
			ref={ref}
			className={`${styles["file"]} ${noBorder ? styles["no-border"] : ""}`}
			style={{
				maxWidth: size === "small" ? "120px" : "200px",
				...wrapperStyle,
			}}
		>
			{icon && getIcon}
			<Tooltip title={fileName} placement="bottom">
				<p
					onClick={canDownload ? onDownload : () => null}
					style={{ cursor: canDownload ? "pointer" : "text" }}
					className={`${styles["file-name"]}`}
				>
					{fileName}
				</p>
			</Tooltip>
			{onRemove && !loading &&
				(popover ? (
					<Popconfirm
						title="Are you sure you want to delete?"
						id="pop-confirm-remove-button"
						content={
							<img
								src={DeleteFileIcon}
								className="cursor-pointer"
								style={{ margin: "auto 0", display: "block" }}
							/>
						}
						onConfirm={onRemove}
						placement="leftTop"
						{...popConfirmProps}
					/>
				) : (
					<img
						src={DeleteFileIcon}
						onClick={(e) => {
							e.stopPropagation();
							onRemove?.();
						}}
						className="cursor-pointer"
					/>
				))}
			{loading && (
				<div className={styles.spinner}>
					<Spinner size="small" />
				</div>
			)}
		</div>
	);
};

UploadedFile.defaultProps = {
	size: "small",
};

export default UploadedFile;
