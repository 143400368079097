import { IPolicy } from "../constants/role-permissions";
import { checkScope } from "../utils/function-utils";

import { useCurrentUser } from "./use-current-user";

const useRolePermission = (policyService: IPolicy) => {
	const { user } = useCurrentUser();

	return {
		canCreate: checkScope(user, policyService, "CREATE"),
		canView: checkScope(user, policyService, "VIEW"),
		canEdit: checkScope(user, policyService, "EDIT"),
		canDelete: checkScope(user, policyService, "DELETE"),
	};
};

export { useRolePermission };
