import { useRef } from "react";
import { AutoComplete as AntdAutoComplete, AutoCompleteProps } from "antd";

import Spinner from "../../../components/spinner";

import styles from "./autoComplete.module.scss";

interface IProps extends AutoCompleteProps {
	loading?: boolean;
}

export const AutoComplete = (props: IProps) => {
	const { className, loading, ...rest } = props;
	const ref = useRef<any>();

	return (
		<div className={styles["wrapper"]} ref={ref}>
			<AntdAutoComplete
				className={`${styles["autocomplete-wrapper"]} ${className || ""}`}
				getPopupContainer={() => ref.current}
				dropdownRender={(menu) => {
					return (
						<div className={`${styles["dropdown-wrapper"]} ${loading ? styles["has-loader"] : ""}`}>
							{loading && (
								<div className={styles["loading-wrapper"]}>
									<Spinner size="small" wrapperStyle={{ margin: 0, padding: 0 }} />
								</div>
							)}
							{menu}
						</div>
					);
				}}
				{...rest}
			/>
			{loading && (
				<div className={styles["input-loader"]}>
					<Spinner size="small" wrapperStyle={{ margin: 0, padding: 0 }} />
				</div>
			)}
		</div>
	);
};
