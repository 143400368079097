import { createContext, useContext } from "react";
import { IUser } from "../constants/user-interfaces";
import { SyncApprovalsCountParams } from "../utils/common-interfaces";
import { IApprovalsCount, ICountryList } from "../utils/common-interfaces";

interface AppContextProps {
	user?: IUser;
	syncUser?: (data: IUser) => void;
	countries?: ICountryList;
	setCountries?: (data: ICountryList) => void;
	getCountries?: () => string[];
	getStates?: (country: string) => string[];
	getCity?: (country: string, state: string) => string[];
	contentHeight: number;
	contentWidth: number;
	loadCurrentUser: (
		syncUser: ((data: IUser) => void) | undefined,
		successMessage?: string,
		hideTeamMemberLoader?: boolean
	) => void;
	isClockedIn: boolean;
	isClockInAndOutLoading: boolean;
	fetchClockedInStatus: () => void;
	clockInAndOut: () => void;
	approvalsCount?: IApprovalsCount;
	syncApprovalsCount: (params: SyncApprovalsCountParams) => void;
}

export const AppContext = createContext<AppContextProps>({
	user: undefined,
	contentHeight: 0,
	contentWidth: 0,
	countries: [],
	getCountries: undefined,
	getStates: undefined,
	getCity: undefined,
	loadCurrentUser: () => null,
	isClockedIn: false,
	isClockInAndOutLoading: false,
	fetchClockedInStatus: () => null,
	clockInAndOut: () => null,
	approvalsCount: undefined,
	syncApprovalsCount: () => null,
});

export function useCurrentUser() {
	const { user, syncUser, loadCurrentUser } = useContext<AppContextProps>(AppContext);
	return { user, syncUser, loadCurrentUser };
}

export function useScreenWidthHeight() {
	const { contentHeight, contentWidth } = useContext<AppContextProps>(AppContext);
	return { contentHeight, contentWidth };
}

export function useCountryList() {
	const { countries, setCountries, getCountries, getCity, getStates } =
		useContext<AppContextProps>(AppContext);
	return { countries, setCountries, getCountries, getCity, getStates };
}

export function useAttendance() {
	const { isClockedIn, isClockInAndOutLoading, fetchClockedInStatus, clockInAndOut } =
		useContext<AppContextProps>(AppContext);
	return { isClockedIn, isClockInAndOutLoading, fetchClockedInStatus, clockInAndOut };
}

export function useApprovalsCount() {
	const { approvalsCount, syncApprovalsCount } = useContext<AppContextProps>(AppContext);
	return { approvalsCount, syncApprovalsCount };
}
