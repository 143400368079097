export const mobileRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

export const epfRegex = /^[A-Z]{2}?[A-Z]{3}?\d{7}?\d{3}?\d{7}$/;

export const esiRegex = /^\d{17}$/;

export const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/i;

export const startsWithAlphanumericRegex = /^[A-Za-z0-9]/;
