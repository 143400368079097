import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { routeObjectType } from "../../../constants/routes";
import { ReactComponent as BackIcon } from "../../../assets/images/chevron-left.svg";
import LeftNavItem from "../../left-menu/LeftNavItem";
import CustomButton from "../../button";
import { useCurrentUser, useApprovalsCount } from "../../../hooks";

import styles from "./nested-menu-layout.module.scss";

// NestedMenuLayout component is a custom layout for nested routes.
// It provides a nested navigation menu and a content area for displaying nested routes.
// The component is designed to handle routes with multiple levels of depth and groups.
const NestedMenuLayout = ({
	children,
	routes,
}: {
	children: React.ReactNode;
	routes: routeObjectType;
}) => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const { user } = useCurrentUser();
	const { approvalsCount } = useApprovalsCount();
	const [activeMenuLevel, setActiveMenuLevel] = useState(0); // State to keep track of the active menu level.
	const [routesStack, setRoutesStack] = useState<routeObjectType[]>([]); // State to maintain the stack of routes for navigation.

	useEffect(() => {
		const subRoutes = Object.values(routes.subRoutes ?? {});
		if (subRoutes.length !== 0 && pathname === routes.route) {
			navigate(subRoutes[0].route);
		}
	}, [pathname, routes, navigate]);

	// The useEffect hook initializes the routesStack state based on the current pathname.
	// It recursively finds the matching routes based on the URL path segments and sets the routesStack accordingly.
	useEffect(() => {
		let currentRoute = Object.values(routes["subRoutes"] ?? {});

		const tempRoutes = pathname
			.split("/")
			.map((path) =>
				currentRoute.find((route) => {
					const condition = route.route.split("/").pop() === path;
					if (condition && route.subRoutes) {
						currentRoute = Object.values(route.subRoutes ?? {});
					}
					return condition;
				})
			)
			.filter((value) => value?.subRoutes);

		// checking routes for the permission
		const finalRoutes = tempRoutes.filter((value) =>
			user?.currentRole.policies[value?.service ?? ""].includes("VIEW")
		);

		setActiveMenuLevel(finalRoutes.length);
		setRoutesStack([routes, ...(finalRoutes as routeObjectType[])]);
	}, [pathname]);

	const getTitleWithCount = (value: routeObjectType): React.ReactNode => {
		if (value.service === "APPROVALS_TIME_OFF") {
			return (
				<p>
					{value.name}{" "}
					<span
						className={styles["approval-count"]}
					>{`(${approvalsCount?.leave_applications})`}</span>
				</p>
			);
		} else if (value.service === "APPROVALS_REIMBURSEMENTS") {
			return (
				<p>
					{value.name}{" "}
					<span
						className={styles["approval-count"]}
					>{`(${approvalsCount?.reimbursement_requests})`}</span>
				</p>
			);
		} else {
			return (
				<p>
					{value.name}{" "}
					<span
						className={styles["approval-count"]}
					>{`(${approvalsCount?.attendance_update_requests})`}</span>
				</p>
			);
		}
	};

	// toggleNextLevel function is used to navigate to the next level of the nested route.
	// It adds the selected route to the routesStack.
	const toggleNextLevel = (value: routeObjectType) => {
		setRoutesStack((prev) => [...(prev ?? []), value]);
		setTimeout(() => {
			setActiveMenuLevel((prev) => prev + 1);
			navigateToIndexPath(value);
		}, 100);
	};

	// togglePrevLevel function is used to navigate to the previous level of the nested route.
	// It decreases the activeLevel state, and updates the routesStack.
	const togglePrevLevel = () => {
		setActiveMenuLevel((prev) => prev - 1);
		setTimeout(() => {
			setRoutesStack((prev) => {
				prev?.pop();
				return [...(prev ?? [])];
			});
			const value = routesStack[routesStack.length - 1];
			navigateToIndexPath(value);
		}, 100);
	};

	// renderNavElement function renders the navigation elements based on the route object.
	// If the route has sub-routes, it renders a switcher; otherwise, it renders a LeftNavItem.
	const renderNavElement = (value: routeObjectType, parentPolicy: string) => {
		if (
			user?.currentRole?.policies?.[parentPolicy]?.includes("VIEW") &&
			user?.currentRole.policies?.[value.service]?.includes("VIEW")
		) {
			return value?.subRoutes ? (
				<CustomButton
					onClick={() => toggleNextLevel(value)}
					className={styles["sub-menu-switcher"]}
					key={"switcher_" + value.service}
				>
					{value.name}
				</CustomButton>
			) : (
				<LeftNavItem
					key={value.name}
					isTabActive={pathname?.includes(value?.route) || false}
					itemKey={value.name}
					menuCollapsed={false}
					redirectRoute={value.route}
					title={
						parentPolicy === "APPROVALS" && approvalsCount ? getTitleWithCount(value) : value.name
					}
					tooltip={false}
					icon={false}
					customClass={styles["link"]}
				/>
			);
		}
	};

	// Function to check and correct the index path when necessary.
	const navigateToIndexPath = (value: routeObjectType) => {
		const routes = Object.values(value?.subRoutes ?? {});
		if (routes) {
			for (const route of routes) {
				if (user?.currentRole?.policies?.[route?.service]?.includes("VIEW")) {
					navigate(route.route ?? "/");
					break;
				}
			}
		} else {
			if (value?.route) navigate(value?.route);
		}
	};

	// Function to calculate the groups based on the route object.
	const calculateGroups = (route: routeObjectType) => {
		const groups = new Set<string>();
		Object.values(route?.subRoutes ?? {}).forEach(
			(value) => value?.group && groups.add(value.group)
		);
		return Array.from(groups);
	};

	return (
		<div className={styles["wrapper"]}>
			<div className={`${styles["sub-menu"]}`}>
				{routesStack &&
					routesStack.length > 0 &&
					routesStack.map((route, index) => {
						const groups = calculateGroups(route) || [];
						return (
							<div
								className={styles["column"]}
								key={route?.name}
								style={{
									transform: `translateX(-${activeMenuLevel * 100}%)`,
								}}
							>
								{routesStack[index - 1] ? (
									<div className={`${styles["sub_menu_title"]}`}>
										<CustomButton
											type="link"
											icon={<BackIcon />}
											onClick={togglePrevLevel}
											style={{ marginLeft: -4 }}
											id="nested-menu-back-button"
										>
											Back
										</CustomButton>
										<div>{route?.name}</div>
									</div>
								) : (
									<div className={styles["title"]}>{route?.name}</div>
								)}
								<div>
									{Object.values(route?.subRoutes ?? {})
										?.filter((route) => !route?.group)
										?.map((value) => renderNavElement(value, route?.service))}
									{groups?.map((group) => (
										<React.Fragment key={group}>
											<div className={styles["group-name"]} key={group}>
												{group ?? ""}
											</div>
											{Object.values(route?.subRoutes ?? {})
												?.filter((value) => value?.group === group)
												?.map((value) => renderNavElement(value, route.service))}
										</React.Fragment>
									))}
								</div>
							</div>
						);
					})}
			</div>
			<div className={styles["content"]}>{children}</div>
		</div>
	);
};

export default NestedMenuLayout;
