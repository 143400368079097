import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

import { useQuery } from "../../hooks";
import Spinner from "../../components/spinner";
import { api } from "../../api";
import { getApiErrorMsg } from "../../utils/object-util";

const CalendarApp = () => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [calendarAppUrl, setCalendarAppUrl] = useState<string>();
	const { query } = useQuery();
	const token = query.get("token");
	const navigate = useNavigate();

	useEffect(() => {
		if (token) {
			api
				.get({
					path: `/public/candidates`,
					params: { token },
					service: "job",
				})
				.then((response) => {
					if (response.data.data) {
						const candidateData = response.data.data;
						const url = // candidateData?.stages[0]?.stage?.interviewer[0]?.team_members[0]
							//   ?.calendar_event_link;
							candidateData?.stages[0]?.stage?.interviewer[0]?.team_members[0]?.calendar_event_link?.replace(
								"8001",
								"3001"
							);
						setCalendarAppUrl(
							`${url}?name=${
								candidateData.full_name
							}&email=${encodeURIComponent(candidateData.email)}`
						);
						document.body.classList.add("remove-body-background");
					}
				})
				.catch((error) => {
					message.error({
						content: getApiErrorMsg(error),
						duration: 3,
						key: "error",
					});
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			setIsLoading(false);
		}

		const eventListener = (event: any) => {
			if (event.data && event.data.source === "Calendar App") {
				navigate(`/calendly-success?token=${token}`, {
					replace: true,
					state: event.data,
				});
			}
		};

		window.addEventListener("message", eventListener);
		() => {
			document.body.classList.remove("remove-body-background");
			window.removeEventListener("message", eventListener);
		};
	}, []);

	return isLoading ? (
		<Spinner />
	) : (
		<iframe
			src={calendarAppUrl}
			title="Calendar App"
			style={{
				height: "100vh",
				width: "100vw",
			}}
		/>
	);
};

export default CalendarApp;
