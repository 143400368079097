import { useState } from "react";
import { Form, FormItemProps, InputProps } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import { Input } from "../form-fields";
import { passwordRegex } from "../../utils/regexp";

import styles from "./passwordPolicies.module.scss";

const PasswordPolicies = ({
	rulesCustomClass,
	inputProps,
	itemProps,
	isConfirmPassword,
	wrapperClass,
}: {
	rulesCustomClass?: string;
	wrapperClass?: string;
	itemProps?: FormItemProps;
	inputProps?: InputProps;
	isConfirmPassword?: boolean;
}) => {
	const [checks, setChecks] = useState({
		capitalLetterCheck: false,
		smallLetterCheck: false,
		specialCharacterCheck: false,
		passwordLengthCheck: false,
		numberCheck: false,
	});

	const rules = [
		{ title: "One lowercase character", checked: checks.smallLetterCheck },
		{ title: "One special character from ! @ # $ % ^ & * ", checked: checks.specialCharacterCheck },
		{ title: "One uppercase character", checked: checks.capitalLetterCheck },
		{ title: "8 characters minimum", checked: checks.passwordLengthCheck },
		{ title: "One number", checked: checks.numberCheck },
	];

	const handlePasswordCheck = (e: any) => {
		const { value } = e.target;
		const capitalLetterCheck = /[A-Z]/.test(value);
		const smallLetterCheck = /[a-z]/.test(value);
		const specialCharacterCheck = /[!@#$%^&*]/.test(value);
		const passwordLengthCheck = value.length >= 8;
		const numberCheck = /\d/.test(value);
		setChecks({
			capitalLetterCheck,
			smallLetterCheck,
			specialCharacterCheck,
			passwordLengthCheck,
			numberCheck,
		});
	};

	const tooltipContent = (
		<div className={`${styles["password-validation"]} ${rulesCustomClass ?? ""}`}>
			{rules.map((el) => (
				<div key={el.title} className={styles["validation"]}>
					<div className={styles["dot"]}></div>
					<p className={styles["validation-name"]}>{el.title}</p>
				</div>
			))}
		</div>
	);

	return (
		<>
			<Form.Item
				rules={[
					{ required: true, message: "Please input your password" },
					() => ({
						validator(_, value) {
							if (value && !passwordRegex.test(value)) {
								return Promise.reject(new Error("Password doesn't satisfy policies!"));
							} else {
								return Promise.resolve();
							}
						},
					}),
				]}
				{...itemProps}
				label={itemProps?.label ?? "Password"}
				name={itemProps?.name ?? "password"}
				tooltip={{
					title: tooltipContent,
					icon: <InfoCircleOutlined />,
					overlayStyle: { maxWidth: "300px" },
				}}
			>
				<Input.Password
					placeholder="Password"
					{...inputProps}
					onChange={(e) => {
						handlePasswordCheck(e);
						inputProps?.onChange?.(e);
					}}
				/>
			</Form.Item>
			{isConfirmPassword && (
				<Form.Item
					name="confirm"
					label="Confirm Password"
					dependencies={["password"]}
					rules={[
						{
							required: true,
							message: "Please confirm your password",
						},
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || !getFieldValue("password") || getFieldValue("password") === value) {
									return Promise.resolve();
								}
								return Promise.reject(new Error("Password doesn’t match"));
							},
						}),
					]}
				>
					<Input.Password placeholder="Enter Password again" />
				</Form.Item>
			)}
		</>
	);
};

export default PasswordPolicies;
