import React from "react";
import {message } from "antd";
import { RcFile } from "antd/es/upload";
import { Upload as AntdUpload,UploadProps } from "antd/lib";
import { AxiosResponse } from "axios";

import { api } from "../../api";
import uploadCloud from "../../assets/images/upload-cloud.svg";
import { ApiServiceType } from "../../api/interfaces";
import { getApiErrorMsg } from "../../utils/object-util";
import Spinner from "../../components/spinner";

import { fileType, hasFileNameAlphanumericStart } from "./utils";
import styles from "./upload.module.scss";

interface IProps extends UploadProps {
	maxSize?: number;
	successCallback?: (response: AxiosResponse<any, any>) => void;
	errorCallback?: (error: any) => void;
	finalCallback?: () => void;
	children?: React.ReactNode;
	className?: string;
	style?: React.CSSProperties;
	ref?: React.RefObject<any>;
	uploadAPI?: {
		path?: string;
		service?: ApiServiceType;
	};
	preUploadCallback?: (file?: RcFile) => void;
	customUpload?: (file: RcFile) => void;
	uploadingFile?: boolean;
	setUploadingFile?: React.Dispatch<React.SetStateAction<boolean>>;
	useDragger?: boolean;
	draggerText?: string;
	draggerIcon?: string;
	draggerBodyClassName?: string;
	draggerBodyStyle?: React.CSSProperties;
}

const Upload = (props: IProps) => {
	const {
		maxSize = 2,
		successCallback,
		errorCallback,
		finalCallback,
		className,
		style,
		children,
		uploadingFile,
		setUploadingFile,
		preUploadCallback,
		ref,
		uploadAPI,
		useDragger = false,
		customUpload,
		accept,
		draggerText,
		draggerIcon,
		draggerBodyClassName,
		draggerBodyStyle,
		...rest
	} = props;

	const uploadProps: UploadProps = {
		name: "file",
		fileList: [],
		showUploadList: false,
		accept: props?.accept,
		beforeUpload: (file: RcFile) => {
			const fileTypes = props?.accept?.split(",");
			const isValidType = fileTypes?.includes(`.${fileType(file)}`) || accept === "image/*";
			if (!isValidType) {
				message.error(`${file.name} is not allowed`);
				return AntdUpload.LIST_IGNORE;
			}

			if (!hasFileNameAlphanumericStart(file.name)) {
				return AntdUpload.LIST_IGNORE;
			}

			if (maxSize && file.size > maxSize * 1024 * 1024) {
				message.error(`${file.name} is too large! Please select a file under ${maxSize}MB.`);
				return AntdUpload.LIST_IGNORE;
			}

			if (customUpload) {
				customUpload(file);
				return AntdUpload.LIST_IGNORE;
			} else {
				uploadFile(file);
				return AntdUpload.LIST_IGNORE;
			}
		},
		customRequest: () => null,
	};

	const uploadFile = (file: RcFile) => {
		if (file.size >= maxSize * 1024 * 1024) {
			message.error({
				content: `File too large! Please select a file under ${maxSize}MB.`,
				key: "uploadfiles",
				duration: 4,
			});
		} else {
			const formData = new FormData();
			formData.append("file", file);
			if (setUploadingFile) setUploadingFile(true);
			if (preUploadCallback) {
				preUploadCallback(file);
			}
			api
				.post({
					path: uploadAPI?.path ?? "/upload",
					service: uploadAPI?.service ?? "job",
					formdata: formData,
				})
				.then((response) => {
					if (response?.data?.data?.file) {
						if (successCallback) successCallback(response);
					}
				})
				.catch((err) => {
					message.error({
						content: getApiErrorMsg(err),
						key: "uploadfiles",
						duration: 3,
					});
					if (errorCallback) errorCallback(err);
				})
				.finally(() => {
					if (setUploadingFile) setUploadingFile(false);
					if (finalCallback) finalCallback();
				});
		}
	};

	return !useDragger ? (
		<AntdUpload
			{...uploadProps}
			className={`${className ? className : ""}`}
			style={style}
			ref={ref}
			{...rest}
		>
			{children}
		</AntdUpload>
	) : (
		<AntdUpload.Dragger
			{...uploadProps}
			className={`${className ? className : ""} ${styles["dragger-wrapper"]}`}
			style={style}
			ref={ref}
			{...rest}
		>
			{uploadingFile ? (
				<>
					<Spinner wrapperStyle={{ paddingTop: 0 }} />
					<div className={styles["uploading-text"]}>Uploading file...</div>
				</>
			) : children ? (
				children
			) : (
				<div
					className={`${styles["upload-body"]} ${draggerBodyClassName ?? ""}`}
					style={draggerBodyStyle}
				>
					<img
						src={draggerIcon ?? uploadCloud}
						className={styles["upload-icon"]}
						alt="Upload Cloud Icon"
					/>
					<div className={styles["upload-text"]}>
						{draggerText ?? "Drop file here or click to select"}
					</div>
				</div>
			)}
		</AntdUpload.Dragger>
	);
};

export default Upload;
