import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { Col, Form, FormInstance, Row } from "antd";

import Heading from "../../../../components/heading";
import UploadedFile from "../../../../components/uploaded-file";
import Spinner from "../../../../components/spinner";
import Upload from "../../../../components/upload";

import styles from "./buildProfile.module.scss";

export interface IUserDocs {
	name: string;
	url: string;
	id?: string;
}

const DocumentsUploadForm = ({
	form,
	hideTitle = false,
	previousFiles,
	setPreviousFiles,
}: {
	form: FormInstance<any>;
	hideTitle?: boolean;
	previousFiles?: IUserDocs[];
	setPreviousFiles?: React.Dispatch<React.SetStateAction<IUserDocs[]>>;
}) => {
	const [uploadingFiles, setUploadingFiles] = useState<{ [key: string]: boolean }>({});
	const [uploadedFiles, setUploadedFiles] = useState<IUserDocs[]>([]);

	useEffect(() => {
		if (previousFiles) {
			setUploadedFiles(previousFiles);
		}
	}, [previousFiles]);

	const fileTypes: {
		name: string;
		required: boolean;
		description: string;
	}[] = [
		{
			name: "ID Proof",
			required: true,
			description: "Aadhaar card, Passport, PAN card etc",
		},
		{
			name: "Address Proof",
			required: true,
			description: "Aadhaar card, Utility bills, Passport etc",
		},
		{
			name: "Highest Education Certificate",
			required: true,
			description: "Higher Secondary, Graduation, Diploma etc",
		},
		{
			name: "Previous Employment Letter",
			required: false,
			description: "if applicable",
		},
		{
			name: "Last Pay Slip",
			required: false,
			description: "if applicable",
		},
	];

	const uploadSuccessCallback = (response: AxiosResponse<any, any>, fileTypeName: string) => {
		const uploadedUrl = response.data?.data?.file;
		if (uploadedUrl) {
			handleFileChange(fileTypeName, uploadedUrl);
		}
	};

	const uploadFinalCallback = (fileTypeName: string) => {
		setUploadingFiles((prev) => ({ ...prev, [fileTypeName]: false }));
	};

	const preUploadCallback = (fileTypeName: string) => {
		setUploadingFiles((prev) => ({ ...prev, [fileTypeName]: true }));
	};

	const handleFileChange = (fileTypeName: string, fileUrl: string) => {
		const newFileData = { name: fileTypeName, url: fileUrl };

		const existingFile = uploadedFiles.find((file) => file.name === fileTypeName);
		if (existingFile) {
			setUploadedFiles((prev) => {
				const newArr = [...prev];
				const index = newArr.findIndex((file) => file.name === fileTypeName);
				newArr[index] = newFileData;
				return newArr;
			});
		} else {
			setUploadedFiles((prev) => [...(prev ?? []), newFileData]);
		}

		form.setFieldsValue({
			[fileTypeName]: newFileData.url,
		});
	};

	const handleRemoveFile = (fileName: string) => {
		setUploadedFiles((prev) => prev.filter((file) => file.name !== fileName));
		form.setFieldsValue({
			[fileName]: undefined,
		});
	};

	const findUploadedFileUrl = (fileName: string) => {
		const uploadedFile = uploadedFiles.find((file) => file.name === fileName);
		return uploadedFile?.url;
	};

	return (
		<>
			{!hideTitle && <Heading title="New Joinee Documents" />}

			<Row gutter={20}>
				{fileTypes.map((file, index) => (
					<Col key={index} xs={24} sm={12} md={8}>
						<Form.Item
							label={file.name}
							name={file.name}
							rules={[
								{
									required: file.required,
									message: `Please upload your ${file.name.toLowerCase()}!`,
								},
							]}
							extra={
								uploadingFiles[file.name] || findUploadedFileUrl(file.name) ? "" : file.description
							}
						>
							{uploadingFiles[file.name] ? (
								<Spinner />
							) : findUploadedFileUrl(file.name) ? (
								<UploadedFile
									url={findUploadedFileUrl(file.name) ?? ""}
									onRemove={() => handleRemoveFile(file.name)}
									icon
									wrapperStyle={{ height: "100%" }}
									size="large"
								/>
							) : (
								<Upload
									useDragger
									name="file"
									multiple={false}
									accept=".pdf,.png,.doc,.jpeg"
									preUploadCallback={() => preUploadCallback(file.name)}
									successCallback={(response) => uploadSuccessCallback(response, file.name)}
									finalCallback={() => uploadFinalCallback(file.name)}
									className={styles["upload"]}
									draggerBodyClassName={styles["dragger-body"]}
								/>
							)}
						</Form.Item>
					</Col>
				))}
			</Row>
		</>
	);
};

export default DocumentsUploadForm;
