import { useEffect } from "react";
import { Form, Empty } from "antd";
import { ColumnsType } from "antd/lib/table";

import Table from "../../../../../components/table";
import { Radio } from "../../../../../components/form-fields";
import { skillLevels } from "../../../../../constants";
import { isNotEmptyArray } from "../../../../../utils/type-util";

import { ISkillsEvaluate } from "../../../../../utils/common-interfaces";

import styles from "../candidateShortlistForm.module.scss";

interface CandidateSkillsProps {
  isSubmitFailed: boolean; // Variable to check whether the form submittion has been failed.
}

const CandidateSkillsForm = (props: CandidateSkillsProps) => {
  const { isSubmitFailed } = props;
  const form = Form.useFormInstance();

  const watchSkills: string[] = Form.useWatch("skills", form);
  const watchSkillsEvaluate = Form.useWatch("skills_evaluate", form);

  const skillsArr: ISkillsEvaluate[] =
    watchSkills?.map((el) => {
      const evaluation =
        (isNotEmptyArray(watchSkillsEvaluate) &&
          watchSkillsEvaluate?.find((innerEl: any) => innerEl.skills === el)
            ?.evaluate) ||
        "";
      return { skills: el, evaluate: evaluation };
    }) || [];

  useEffect(() => {
    if (watchSkills) {
      const arr: { skills: string; evaluate?: string }[] = [];
      const prevVal: ISkillsEvaluate[] =
        form.getFieldValue("skills_evaluate") || [];
      watchSkills?.forEach((skill: string) => {
        const prevSkillValue = prevVal.find(
          (el) => el.skills === skill
        )?.evaluate;
        arr.push({ skills: skill, evaluate: prevSkillValue });
      });
      form.setFieldValue("skills_evaluate", arr);
      form.validateFields(["skills_evaluate"]);
    }
  }, [watchSkills]);

  const formColumns: ColumnsType<ISkillsEvaluate> = [
    {
      title: "",
      dataIndex: "skills",
      key: "skills",
      width: 100,
      render: (value, record, index) => {
        return (
          <>
            <Form.Item name={["skills_evaluate", index, "skills"]} />
            <p>{value}</p>
          </>
        );
      },
    },
    {
      title: "",
      key: "",
      children: skillLevels.map((el) => ({
        title: el.label,
        dataIndex: "skills_evaluate",
        key: el.value,
        width: 80,
        render: (value, record, index) => {
          return (
            <Form.Item name={[index, "evaluate"]}>
              <Radio.Group
                onChange={() => form.validateFields(["skills_evaluate"])}
              >
                <Radio value={el.value} />
              </Radio.Group>
            </Form.Item>
          );
        },
      })),
    },
  ];

  return (
    <Form.List
      name="skills_evaluate"
      rules={[
        {
          validator: async (_, value) => {
            if (
              !value ||
              value.length !== skillsArr.length ||
              value.findIndex((el: any) => !el?.evaluate) !== -1
            ) {
              return Promise.reject(
                new Error("Please evaluate all skills of the candidate!")
              );
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      {(fields, functions, { errors }) => {
        return (
          <>
            <Table
              columns={formColumns}
              rowKey={(record: any) => record.skills}
              rows={skillsArr}
              pagination={false}
              className={styles["skill-table"]}
              locale={{
                emptyText: (
                  <Empty
                    description="Please enter skills above for evaluation"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                ),
              }}
            />
            {isSubmitFailed && <Form.ErrorList errors={errors} />}
          </>
        );
      }}
    </Form.List>
  );
};

export default CandidateSkillsForm;
