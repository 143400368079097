import { useState } from "react";
import html2canvas from "html2canvas";
import { Form, message, Modal, Flex } from "antd";
import dayjs from "dayjs";

import { TextArea } from "../form-fields";
import { ReactComponent as FeedbackIcon } from "../../assets/images/feedback.svg";
import { api } from "../../api";
import { getApiErrorMsg } from "../../utils/object-util";
import CustomButton from "../button";
import { useCurrentUser } from "../../hooks";

import styles from "./submitFeedback.module.scss";

interface IFormValues {
	note: string;
}

const { Item } = Form;

const SubmitFeedback = () => {
	const [form] = Form.useForm();
	const { user } = useCurrentUser();

	const [screenshotDataURL, setScreenshotDataURL] = useState("");
	const [uploadingScreenshot, setUploadingScreenshot] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isSuccessMsgVisible, setIsSuccessMsgVisible] = useState(false);

	const captureScreenshot = async () => {
		const rootElement = document.getElementById("root");
		const leftMenu = document.getElementById("left-menu");
		if (rootElement && leftMenu) {
			if (leftMenu && leftMenu.clientHeight < leftMenu.scrollHeight) {
				const header = document?.getElementById("header");
				const temp = leftMenu.scrollHeight + (header?.offsetHeight || 64);
				if (rootElement.clientHeight < temp) {
					rootElement.style.height = `${temp}px`;
					leftMenu.style.height = `${temp}px`;
				} else {
					leftMenu.style.height = `${rootElement.clientHeight}px`;
				}
				await new Promise((resolve) => setTimeout(resolve, 100));
			}
			const canvas = await html2canvas(rootElement, {
				useCORS: true,
				allowTaint: true,
			});
			const image = canvas.toDataURL("image/png");
			rootElement.style.height = "";
			leftMenu.style.height = "";
			setScreenshotDataURL(image);
		}
	};

	const onFeedbackClick = () => {
		setIsModalVisible(true);
		captureScreenshot();
	};

	const uploadScreenshot = async (imageDataUrl: string, onSuccess: (fileUrl: string) => void) => {
		const formData = new FormData();
		const blob = await fetch(imageDataUrl).then((r) => r.blob());
		formData.append(
			"file",
			blob,
			`feedback_screenshot_${user?.id}_${dayjs().format("DD-MM-YYYY_HH:mm:ss")}.png`
		);
		setUploadingScreenshot(true);
		setSubmitting(true);
		api
			.post({
				path: "/upload",
				service: "job",
				formdata: formData,
			})
			.then((response) => {
				setUploadingScreenshot(false);
				if (response.data?.data?.file) {
					onSuccess(response.data.data.file);
				}
			})
			.catch((error) => {
				setUploadingScreenshot(false);
				setSubmitting(false);
				message.error({
					content: getApiErrorMsg(error),
					key: "uploadimage",
					duration: 3,
				});
			});
	};

	const onCancel = () => {
		setScreenshotDataURL("");
		setIsModalVisible(false);
	};

	const onClose = () => {
		form.resetFields();
		onCancel();
		setIsSuccessMsgVisible(false);
	};

	const finalSubmit = (values: IFormValues, fileUrl: string) => {
		const payload: any = {};
		payload.note = values.note.trim();
		payload.screenShot = fileUrl;
		payload.screenUrl = window.location.href;

		setSubmitting(true);
		api
			.post({ path: "/feedbacks", service: "auth", formdata: payload })
			.then(() => {
				setSubmitting(false);
				setIsSuccessMsgVisible(true);
			})
			.catch((error) => {
				setSubmitting(false);
				message.error({
					content: getApiErrorMsg(error),
					key: "give-feedback",
					duration: 3,
				});
			});
	};

	const onFormFinish = (values: IFormValues) => {
		uploadScreenshot(screenshotDataURL, (fileUrl) => finalSubmit(values, fileUrl));
	};

	const isSubmitting = uploadingScreenshot || submitting;

	return (
		<>
			{!user?.is_super && (
				<CustomButton
					type="primary"
					icon={<FeedbackIcon />}
					onClick={onFeedbackClick}
					id="send-feedback-button"
				>
					Send Feedback
				</CustomButton>
			)}

			<Modal
				open={isModalVisible}
				onCancel={() => (isSubmitting ? null : onClose())}
				destroyOnClose
				className={styles["modal-wrapper"]}
				centered
				closable={isSuccessMsgVisible ? false : !isSubmitting}
				keyboard={!isSuccessMsgVisible}
				maskClosable={!isSuccessMsgVisible}
				footer={
					!isSuccessMsgVisible ? (
						<Flex gap={12} className={styles["footer-wrapper"]}>
							<CustomButton
								type="default"
								onClick={() => (isSubmitting ? null : onClose())}
								id="cancel-feedback-button"
							>
								Cancel
							</CustomButton>
							<CustomButton
								type="primary"
								loading={isSubmitting}
								onClick={form.submit}
								id="submit-feedback-button"
							>
								Submit Feedback
							</CustomButton>
						</Flex>
					) : null
				}
			>
				{!isSuccessMsgVisible ? (
					<>
						<div className={styles["header-icon-wrapper"]}>
							<FeedbackIcon width={24} height={24} />
						</div>
						<h2 className={styles.heading}>Autoscal Feedback</h2>
						<p className={styles.description}>
							We welcome your ideas, requests and comments about Autoscal. We will use them to
							improve our service
						</p>
						<Form
							form={form}
							layout="vertical"
							onFinish={onFormFinish}
							disabled={submitting}
							requiredMark={false}
						>
							<Item
								label="Tell us more"
								name="note"
								rules={[{ required: true, whitespace: true, message: "Please enter the feedback" }]}
							>
								<TextArea
									autoSize={{ minRows: 4, maxRows: 4 }}
									placeholder="Please be as detailed as possible. What did you expect and what happened instead?"
								/>
							</Item>
						</Form>
					</>
				) : (
					<Flex justify="center" vertical align="center">
						<div className={styles["header-icon-wrapper"]}>
							<FeedbackIcon width={24} height={24} />
						</div>
						<h2 className={styles.heading}>Thank you for your feedback!</h2>
						<p className={styles.description}>We strive to become better every day!</p>

						<CustomButton type="primary" onClick={onClose} fullWidth id="close-modal-button">
							Done
						</CustomButton>
					</Flex>
				)}
			</Modal>
		</>
	);
};

export default SubmitFeedback;
